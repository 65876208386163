/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from '@emotion/styled'
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import React, { useState, useEffect }  from "react"
import { window, exists } from "browser-monads"
import { navigate } from 'gatsby-link'
import axios from "axios"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"
import Sidebar from "@lekoarts/gatsby-theme-cara/src/components/sidebar"
import SEO from "@lekoarts/gatsby-theme-cara/src/components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressBook, faPhoneSquareAlt, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import Sitemap from "@lekoarts/gatsby-theme-cara/src/components/sitemap"

const Input = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:100%;
`

const InputRadio = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:40px;
`

const SubmitButton = styled.button`
  border: 0px;
    padding: 10px;
    background: #3caf7b;
    color: white;
    font-size: 1em;
    font-weight: 800;
`   

const LongArrowLeft = styled.div`
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid gray;
  border-left: 2px solid gray ;
  transform: rotate(-45deg);
     &:after{
    content: "";
    display: block;
    width: 2px;
    height: 45px;
    background-color: gray;
    transform: rotate(-45deg) translate(15px, 4px);
    left: 0;
    top: 0;
  }
`

const ImageWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  background: linear-gradient(45deg, #ffffff,#ffffff);
  padding:10px;
  margin-top:40px;
`

const reservations = () => {

useEffect(() => {
        window.scrollTo(0,0)
    })

const data = useStaticQuery(graphql`
query {
  ttmlogo: file(relativePath: {eq: "ttm-horiz.png"}) {
    childImageSharp {
      fluid(maxWidth:170){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  events: file(relativePath: {eq: "datacollection/events1.png"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  events2: file(relativePath: {eq: "datacollection/events2.png"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image1: file(relativePath: {eq: "datacollection/brunch.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image2: file(relativePath: {eq: "datacollection/burgers.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image3: file(relativePath: {eq: "datacollection/convention.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image4: file(relativePath: {eq: "datacollection/g2e.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image5: file(relativePath: {eq: "datacollection/indiangaming.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image6: file(relativePath: {eq: "datacollection/lionfight.jpeg"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image7: file(relativePath: {eq: "datacollection/palms.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image8: file(relativePath: {eq: "datacollection/planet.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image9: file(relativePath: {eq: "datacollection/redmile.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image10: file(relativePath: {eq: "datacollection/sawgrass.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image11: file(relativePath: {eq: "datacollection/sdpride.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image12: file(relativePath: {eq: "datacollection/secretsesh.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image13: file(relativePath: {eq: "datacollection/tilted.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image14: file(relativePath: {eq: "datacollection/wckd.png"}) {
    childImageSharp {
      fluid(maxWidth:100){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
}`
)  

  const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/33fc9681-937d-40a3-9776-38ff436db3db",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };

  
    

return (
   
  <div id="outer-container">
  <SEO/>
  <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
        <div id="page-wrap">

          
        
      <div sx={{mt:60,p:20,maxWidth:900,m:'0 auto'}}>
      <a href="/">
          <Img sx={{marginTop:'20px'}}
            fluid={data.ttmlogo.childImageSharp.fluid}
            alt="Touch This Media Logo"
          />   
        </a>
        <h1 sx={{
            color: "heading",
            m: 1,
            mt:80,
            mb:40
        
          }}>Data Collection Outreach Events</h1>

      

         <Img sx={{m:'0 auto'}}
            fluid={data.events.childImageSharp.fluid}
            alt="event"
            style={{ width:`100%`}} 
          />
       
        <div>
        <h2>Solution</h2>
         <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
      Any event where you need to stand out from the competition & get booth traffic- we have it covered. </p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
There are no second chances when unique opportunities to display your brand & onboard thousands of prospects for the purposes of direct marketing comes your way. 
</p>
<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
Touch This Media have deployed hundreds of promotional games on thousands of devices, serving millions of players & awarding millions of dollars in prizes all over North America.  
</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
We offer a unique experience in building applications for deployment in challenging network environments including casino, high traffic retail and outdoor events.  
</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
Using Free2Play sports, awards, TV, pick, spin & scratch contests, we give your prospects an opportunity to register, play a game and win a prize.
</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
A collection of instant prizes, discounts, special offers & BOGO deals give clients every chance to enroll prospects and drive traffic- during & after the event..
</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
We support mobile & self-service solutions with an ability to place up to 150 self-service terminals at any single event location to facilitate mass data collection in an environment where network connectivity and bandwidth are typically limited.
</p>

<p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
Events are available for a little at $399.00 & include hosting, support and maintenance services. Low costs hardware rental options available.
</p>

        <Img
            fluid={data.events2.childImageSharp.fluid}
            alt="Data Collection software at event"
            style={{ width:`100%`}} 
          />

      </div>

      <div>
        <h2>Useful to</h2>
        <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
       Any entity attending any event needing to collect leads electronically. 
        </p>

      </div>

       <div id="ttm-form" sx={{
        m:"0 auto",
        width:'100%',
        padding:40,
        background:'#191e2b'
       }}>
                <h2>Get Connected</h2>
                
                <div>
                    <form name="reservations"  onSubmit={handleOnSubmit}>
                      <Input
                            type="text"
                            placeholder="name"
                            name="name"
                            required
                        />
                        <br/>
                        <Input
                            type="email"
                            placeholder="email"
                            name="email"
                            required
                        />
                        <br />
                        <Input 
                          type="tel"
                          placeholder="phone" 
                          name="phone" 
                        />
                        <br />
                         <Input 
                          type="text"
                          placeholder="company" 
                          name="company" 
                        />
                        <br />
                        <br />
                        <b>Follow up with you?</b>
                        <ul style={{listStyleType:`none`}}>
                          <li>
                                <InputRadio
                                  required
                                    type="radio"
                                    value="Yes"
                                    name="FOLLOWUP"
                                />
                                <label>Yes</label>
                                </li>

                               <li>
                                <InputRadio
                                  required
                                    type="radio"
                                    value="No"
                                    name="FOLLOWUP"
                                /> 
                                <label>No</label>
                                </li>
                        </ul>                                                    
                         <SubmitButton type="submit" disabled={serverState.submitting}> Send </SubmitButton>
                        {serverState.status && (
                        <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                        </p>
                        )}
                    </form>
                </div>
            </div>

          <ImageWrapper>
           <Img
            fluid={data.image1.childImageSharp.fluid}
            alt="Brunch and Booze Logo"
            style={{ width:`100%`}} 
          />

          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt="Burgers and Beer Logo" 
            style={{ width:`100%`}}
          />

          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt="San Diego Convention Center Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt="Global Gaming Expo Logo " 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image5.childImageSharp.fluid}
            alt="Indian Gaming Convention Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image6.childImageSharp.fluid}
            alt="Lion Fight Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image7.childImageSharp.fluid}
            alt="Palms Casino Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image8.childImageSharp.fluid}
            alt="Planet Hollywood Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image9.childImageSharp.fluid}
            alt="RedMile Casino Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image10.childImageSharp.fluid}
            alt="PGA Sawgrass Golf Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image11.childImageSharp.fluid}
            alt="San Diego LGBT Pride Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image12.childImageSharp.fluid}
            alt="Secret Sesh Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image13.childImageSharp.fluid}
            alt="Tilted Kilt Logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image14.childImageSharp.fluid}
            alt="WCKD Logo" 
            style={{ width:`100%`}}
          />
        </ImageWrapper>
        <Sitemap/>
        <div sx={{ m:"0 auto",mt:50,
        width:'100%',alignItems:`center`, textAlign: `center`}}>
         <h4>Touch This Media </h4> 
        <p> <FontAwesomeIcon icon={faAddressBook}/> 3288 Adams Ave <br/>
          P.O. Box 16113 <br/>
          San Diego, CA 92176
        </p>
        <p> <FontAwesomeIcon icon={faPhoneSquareAlt}/> <a href="tel:619-796-2675"> 619-796-2675</a> </p>
        <button sx={{variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`}} type="button"> <FontAwesomeIcon icon={faEnvelopeOpenText}/> <a sx={{color:`background`}}href="mailto:sales@touchthismedia.com"> Email Us </a> </button>
        </div>

    </div>    
    </div>    
    </div>
    
  )
    
}



export default reservations

